import React from "react"

const SvgArrowReturn = props => (
    <svg viewBox="0 0 13.5 11" {...props}>
        <g data-name="Layer 2">
            <path d="M6 5V0L0 6l6 5V6h8V5H6z" data-name="Layer 1" fill="#febe23" />
        </g>
    </svg>
)

export default SvgArrowReturn