import * as React from "react"

function ShopIcon(props) {
  return (
    <svg
      id="prefix__Capa_1"
      x={0}
      y={0}
      viewBox="0 0 27 23.73"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M8.7 15.82h14.35c.35 0 .66-.23.76-.57l3.16-11.07c.07-.24.02-.5-.13-.69a.777.777 0 00-.63-.31H6.87L6.31.62A.791.791 0 005.54 0H.79C.35 0 0 .35 0 .79s.35.79.79.79H4.9l2.86 12.85a2.38 2.38 0 00-1.43 2.18c0 1.31 1.06 2.37 2.37 2.37h14.34c.44 0 .79-.35.79-.79 0-.44-.35-.79-.79-.79H8.7c-.44 0-.79-.35-.79-.79 0-.43.35-.79.79-.79zM7.23 4.75h17.93l-2.71 9.49H9.34L7.23 4.75zM7.91 21.36c0 1.31 1.06 2.37 2.37 2.37s2.37-1.06 2.37-2.37-1.06-2.37-2.37-2.37-2.37 1.06-2.37 2.37zm2.37-.79c.44 0 .79.35.79.79s-.35.79-.79.79c-.44 0-.79-.35-.79-.79s.36-.79.79-.79zM19.09 21.36c0 1.31 1.06 2.37 2.37 2.37s2.37-1.06 2.37-2.37-1.06-2.37-2.37-2.37a2.362 2.362 0 00-2.37 2.37zm2.37-.79c.44 0 .79.35.79.79s-.35.79-.79.79-.79-.35-.79-.79.36-.79.79-.79z"
      />
    </svg>
  )
}

export default ShopIcon
