import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import styles from '../styles/_layout/burger/burger.module.scss'
import CloseIcon from '../components/svg/commons/close_icon';
import CinsaLogo from '../components/svg/commons/cinsa_logo';
import { usePrismCategories } from '../hooks/use_prims_categories';
import { usePrismModels } from '../hooks/use_prism-models';
import { useCurrenDevice, DEVICES } from '../hooks/useCurrentDevice';
import { usePrismCalentadores } from '../hooks/use-prism-calentadores';
import MenuModels from '../components/_menu/_menu_models';

const BurgerMenu = ({ isMenuModels, handleCloseMenu }) =>{
    const categories = usePrismCategories().nodes;
    const models = usePrismModels().nodes;
    const [linksCategories, setLinksCategories] = useState([]);
    const [device] = useCurrenDevice();

    const calentadoresQuery = usePrismCalentadores()

    function get_item(_text,_to, _tag, _key=Math.random()){
        return (
            <Link key={`link-${_key}`} className={`o-burguer__menu__item ${styles.oBurguer__menu__item} ${_tag}`} to={_to} onClick={()=>handleCloseMenu()}>
                <p>{_text}</p>
                <div className={styles.oBurguer__menu__spacer}></div>
            </Link>
        )
    }

    useEffect(() => {

        if(device === DEVICES.desktop) return;

        let links = [];
        categories.map(category => {

            const firstLink = models.filter(model => model.data.category.uid === category.uid )[0];

            if(firstLink===undefined) return false;
            links = [...links, {
                name: category.data.name.text,
                url: firstLink.uid
            }]
        })
        setLinksCategories(links)
    }, [device])
    return (
        <div className={`o-burguer__menu ${styles.oBurguer__menu}`}>
           <div className={styles.oBurguer__menu__stage}>
                <div className={`burger_menu_header ${styles.oBurguer__menu__header}`}>
                    <div className={styles.oBurguer__menu__icon}>
                        <CinsaLogo />
                    </div>
                    <div className={styles.oBurguer__menu__close} onClick={()=>handleCloseMenu()}>
                        <CloseIcon></CloseIcon>
                    </div>
                </div>
                <div className={styles.oBurguer__menu__body}>
                    {
                        isMenuModels ?
                            <MenuModels modelsQuery={ calentadoresQuery } />
                        :
                        <>
                            {get_item('Contáctanos','/contactanos', "tag-burger-contactanos")}
                            {get_item('Nosotros','/nosotros', "tag-burger-nosotros")}
                            {get_item('Código de Ética','/codigo-de-etica', "tag-burger-codigo-de-etica")}
                            {get_item('Compra en linea','/compra-en-linea', "tag-burger-compra-en-linea" )}
                            {get_item('Solicita Ayuda','/solicita-ayuda', "tag-burger-solicita-ayuda" )}
                            {get_item('Distribuidores','/distribuidores', "tag-burger-distribuidores" )}
                            {get_item('Tu calentador ideal','/tu-calentador-ideal', "tag-burger-tu-calentador-ideal")}
                            {get_item('Comparador','/comparador', "tag-burger-comparador")}
                            {get_item('Blog','/blog', "tag-burger-blog")}
                            {get_item('Promociones','/promociones', "tag-burger-promociones")}
                            {get_item('Newsletter','/newsletter', "tag-burger-newsletter")}
                            
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default BurgerMenu
