import React, { useState, useEffect } from 'react';
import { isNullOrUndefined } from 'util';

export const DEVICES = {
    mobile: "mobile",
    tablet: "tablet",
    tabletLandscape: "tabletLandscape",
    desktopMin: "desktopMin",
    desktop: "desktop",
    xlg: "xlg"
};

export function useCurrenDevice(callback) {

    const [device, setDevice] = useState(null);
    const [isFirsTime, setIsFirsTime] = useState(true);

    function getDevice() {
        const width = window.innerWidth;

        if(width < 768) {
            setDevice(DEVICES.mobile);
        } else if (width < 1024) {
            setDevice(DEVICES.tablet);
        } else if(width < 1360){
            setDevice(DEVICES.desktopMin);
        }else if(width < 1920){
            setDevice(DEVICES.desktop);
        }else{
            setDevice(DEVICES.xlg)
        }
    }

    useEffect(() => {

        window.addEventListener("resize", getDevice);
        if(isFirsTime) {
            getDevice();
            setIsFirsTime(false);
        }
        if(!isNullOrUndefined(callback)){
            callback();
        }
        return () => window.removeEventListener("resize", getDevice);
    }, [device]);

    return [device];
}