import { useStaticQuery, graphql } from "gatsby"

export const usePrismCategories = () => {
    const prismic = useStaticQuery(
        graphql`
            query{
                allPrismicCategory(
                    sort: {fields: [data___order], order: ASC}
                ){
                    nodes{
                        uid
                        data{
                            name {
                            text
                            }
                        }
                    }
                }
            }
        `
    );
    return prismic.allPrismicCategory;
}
