/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import BurgerMenu from "./burger_menu"
import Footer from "./footer"
import Chat from '../components/chat/_chat'
import ButtonShop from '../components/buttons/_button_shop'


const Layout = ({children, _class='',chat=true, isBlue=false, isBlueMobile=false, header_visible=false, buttonShop = true}) => {

  const [isMenuModels, setIsMenuModels] = useState(false);

  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  function handleMenuModels(isMenuModelActive){
    setIsMenuModels(isMenuModelActive)
  }

  function close_burger(){
    let body = document.getElementsByTagName("BODY")[0];
    body.classList.remove("body--onburger");
    body.classList.remove("burger_models_active");
  }

  return (
    <>
      {chat ? <Chat/> : null}      
      { buttonShop && <ButtonShop/> }
      <BurgerMenu isMenuModels={isMenuModels} handleCloseMenu={ close_burger } />
      <Header handleMenuModels={handleMenuModels} handleCloseMenu={ close_burger } _className={`${isBlue ? 'is-blue' : ''} ${isBlueMobile ? 'is-blue-mobile' : ''}`} siteTitle={'prueba'} header_visible={header_visible} />
      <div className="stage">
        <div className={`main ${_class}`}>{children}</div>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
