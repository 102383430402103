import React, { useRef } from 'react';
import { Link } from 'gatsby';

import ColorHelper from '../_helpers/_color_helper';

import styles from '../../styles/_components/_buttons/_button_square.module.scss';

export const Button_Square_types = {
    LIGHT: 'oButtonSquareLight',
    REGULAR: ''
}

export const ButtonSquare = ({ _text, _slug, _to = '', _bg_color = '', _textColor = '', _className, _button_type, _activeClassName, _onClick, _is_selected = false }) => {

    const shadow_color = `${ColorHelper.hexToRGB(_bg_color, 0.5)}`
    const type = styles[_button_type || Button_Square_types.REGULAR];
    const class_light = _button_type === Button_Square_types.LIGHT ? 'o-button-square--light' : ' ';
    const class_selected = _is_selected ? 'o-button-square--selected' : '';
    const buttonShadow = useRef(null);

    return (
        _to === '' ?
            _onClick ?
                <div onClick={_onClick} ref={buttonShadow} style={_bg_color !== '' ? {backgroundColor: `${_bg_color}`} : ''}
                    className={`${_className} ${styles.oButtonSquare} ${type} ${class_light} ${class_selected}`}>
                    <p className={styles.oButtonSquare_type_item}>{_text}</p>
                </div>
                :
                <div href="#" ref={buttonShadow} style={_bg_color !== '' ? {backgroundColor: `${_bg_color}`} : ''}
                    className={`${_className} ${styles.oButtonSquare} ${type} ${class_light}`} >
                    <p className={styles.oButtonSquare_type_item}>{_text}</p>
                </div>
            : <Link to={_to} ref={buttonShadow} style={_bg_color !== '' ? {backgroundColor: `${_bg_color}`} : ''}
                className={`${_className} ${styles.oButtonSquare} ${type} ${class_light} ${class_selected} ${_textColor} `} activeClassName={_activeClassName}>
                <p className={`${styles.oButtonSquare_type_item} ${_textColor}`}>{_text}</p>
            </Link>

    );
}