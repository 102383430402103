import React from "react"

const SvgYoutube = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fab"
      data-icon="youtube"
      className="prefix__svg-inline--fa prefix__fa-youtube prefix__fa-w-18"
      viewBox="0 0 576 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M550 124c-7-24-25-42-49-49-42-11-213-11-213-11S117 64 75 75c-24 7-42 25-49 49-11 43-11 132-11 132s0 90 11 133c7 23 25 41 49 48 42 11 213 11 213 11s171 0 213-11c24-7 42-25 49-48 11-43 11-133 11-133s0-89-11-132zM232 338V175l143 81-143 82z"
      />
    </svg>
  )
}

export default SvgYoutube