export class CssClassManager{
    element = null;
    constructor(){
        
    }
    add_class(_element,_class){
        if(_element===null) return false;
        let element, name, arr;
        element = _element;        
        // console.log('element', element);
        name = _class;
        arr = element.className.split(" ");
        if (arr.indexOf(name) == -1) {
            element.className += " " + name;
        }
    }
    remove_class(_element,_class){
        if(_element===null) return false;
        let element = _element
        
        var re = new RegExp(`\\b${_class}\\b`, "g");
        
        element.className = element.className.replace(re, "");
    }
}
export default CssClassManager;