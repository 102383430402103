import React, { useEffect, useState } from 'react'

import '../../styles/_components/_titles/_header_title_stroke.scss'

export const TitleStroke = ({ _className = "", __classNameSpan="", useH1 = false, textStroke, text, colorStroke = "#FFFFFF", colorText = "#FFFFFF", isStroke = true }) => {
    const [styleStrokeVar, setStrokeStyleVar] = useState({})
    const [styleVar] = useState({ color: colorText })

    useEffect(() => {
        if (document !== null) {
            let ie = (document.documentMode ? document.documentMode : 99)
            if (ie < 99 || !isStroke) {
                setStrokeStyleVar({
                    // textShadow: `1px  1px 0 ${colorStroke}, -1px -1px 0 ${colorStroke}, 1px -1px 0 ${colorStroke}, -1px  1px 0 ${colorStroke}, 1px  1px 0 ${colorStroke}`
                    color: `${colorStroke}`
                })
            } else {
                setStrokeStyleVar({
                    WebkitTextStroke: `1px ${colorStroke}`,
                    WebkitTextFillColor: 'transparent'
                })
            }
        }
    }, [])

    return (
        <>
            {
                (useH1 === true || useH1 === "true") ?
                    <h1 className={`c-header_title ${_className}`}>
                        {
                            textStroke &&
                            <span className={`c-header_title_stroke ${__classNameSpan}`} style={styleStrokeVar} dangerouslySetInnerHTML={{ __html: textStroke }} />
                        }
                        {
                            text &&
                            <span className={`c-header_title_filled ${__classNameSpan}`} dangerouslySetInnerHTML={{ __html: text }} style={styleVar} />
                        }
                    </h1>
                    :
                    <h3 className={`c-header_title ${_className}`}>
                        {
                            textStroke &&
                            <span className={`c-header_title_stroke ${__classNameSpan}`} style={styleStrokeVar} dangerouslySetInnerHTML={{ __html: textStroke }} />
                        }
                        {
                            text &&
                            <span className={`c-header_title_filled ${__classNameSpan}`} dangerouslySetInnerHTML={{ __html: text }} style={styleVar} />
                        }
                    </h3>
            }
        </>
    )
}