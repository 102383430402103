import React from "react";
import { Link } from 'gatsby';
import SvgIconShop from '../../components/svg/commons/shop';

import styles from '../../styles/_components/_buttons/_button_shop.module.scss'


const ButtonShop = () => {
    return(
        <Link className={`o-shop_button tag-shop-button ${styles.oShop_container}`} to="/compra-en-linea">
            <span>compra en línea</span>
        </Link>
    )
}
export default ButtonShop 