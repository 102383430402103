import React from "react"

const BlogImageSpot = props => (

  <svg viewBox="0 0 207.1 161.9" {...props}>
    <path
      d="M203.6 34.2C183.4-11.1 96.1-1.4 58.6 7.7c-24.5 6-48.1 21.1-56.1 46.1-6.9 21.7 1.3 44.4 15 61.7 17.7 22.5 44.3 45 74.1 46.3 45.3 2 82.8-34.1 103.2-71.2 9.1-16.6 17.1-37.9 8.8-56.4z"
      fill="#f6f7ff"
    />
  </svg>
)

export default BlogImageSpot
