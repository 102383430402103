import * as React from "react"

const SvgFacebook = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fab"
      data-icon="facebook-f"
      className="prefix__svg-inline--fa prefix__fa-facebook-f prefix__fa-w-10"
      viewBox="0 0 320 512"
      {...props}
    >
      <path
        fill={props.fill}
        d="M279 288l14-93h-89v-60c0-25 13-50 53-50h40V6s-37-6-72-6c-73 0-121 44-121 125v70H23v93h81v224h100V288z"
      />
    </svg>
  )
}

export default SvgFacebook
