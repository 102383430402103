import React from 'react'
import SvgFacebook from '../../../components/svg/social/facebook';
import SvgYoutube from '../../../components/svg/social/youtube';
import SvgPinterest from '../../../components/svg/social/pinterest';

const Social = () =>{
    return(
        <div className="social_container">
            <a href="https://www.​facebook.​com/CinsaBoilers" target="_blank" className="social_network facebook">
                <SvgFacebook/>
            </a>
            <a href="https://www.​youtube.​com/channel/UCaVlsyR57RxJnMcI2xqB2mg?view_as=subscriber" target="_blank" className="social_network youtube">
                <SvgYoutube/>
            </a>
            <a href="https://www.​pinterest.​com.​mx/CinsaBoilers" target="_blank" className="social_network pinterest">
                <SvgPinterest/>
            </a>
        </div>
    )
}
export default Social