import React from 'react'

const CloseIcon = props => (
  <svg viewBox="0 0 37 37" {...props}>
    <linearGradient
      id="prefix__a"
      gradientUnits="userSpaceOnUse"
      x1={10.311}
      y1={37}
      x2={10.311}
      y2={0}
    >
      <stop offset={0} stopColor="#f73855" />
      <stop offset={1} stopColor="#ff6262" />
    </linearGradient>
    <path
      d="M1.5 1.5l17 17-17 17"
      clipRule="evenodd"
      fill="none"
      stroke="url(#prefix__a)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeMiterlimit={10}
    />
    <linearGradient
      id="prefix__b"
      gradientUnits="userSpaceOnUse"
      x1={-532.689}
      y1={37}
      x2={-532.689}
      y2={0}
      gradientTransform="matrix(-1 0 0 1 -506 0)"
    >
      <stop offset={0} stopColor="#f73855" />
      <stop offset={1} stopColor="#ff6262" />
    </linearGradient>
    <path
      d="M35.5 35.5l-17-17 17-17"
      clipRule="evenodd"
      fill="none"
      stroke="url(#prefix__b)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeMiterlimit={10}
    />
  </svg>
)

export default CloseIcon