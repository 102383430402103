import React,{ useRef, useEffect, useState } from "react"
import { Link } from 'gatsby'
import "../styles/_layout/header/header.scss"
import CinsaLogoText from '../components/svg/commons/cinsa_logo_text';
import CssClassManager from '../components/_helpers/_css_class_manager';
import { useCurrenDevice, DEVICES } from '../hooks/useCurrentDevice'

const Header = ({handleMenuModels, handleCloseMenu, _className='', header_visible=false}) => {
  const open_burger_button = useRef(null);
  const menu_hidden = useRef(null);
  const [menuModelsActive, setMenuModelsActive] = useState(false);
  const [device] = useCurrenDevice()

  let valueScroll = 0;
  let cssClassManager = new CssClassManager();

  function open_burger(products){

    let element, name, arr, classModels;
    element = document.getElementsByTagName("BODY")[0];
    name = "body--onburger";
    classModels = "burger_models_active";
    arr = element.className.split(" ");
    if (arr.indexOf(name) === -1) {
        products ? element.className += " " + name + " " + classModels : element.className += " " + name;
    }

    handleMenuModels(products);

    if( device === DEVICES.desktop ){
      if( !menuModelsActive ) {
        setMenuModelsActive(true);

        return
      }

      handleCloseMenu();
      setMenuModelsActive(false)
    }

  }

  useEffect(()=>{
    if(!header_visible){
      window.addEventListener('scroll', listenToScroll)
    }
    return () => {
      handleCloseMenu();
      setMenuModelsActive(false)
    }
  },[])

  function listenToScroll () {
    let windowY = window.pageYOffset;

    if( windowY > 100){
      if(windowY > valueScroll){
        cssClassManager.add_class(menu_hidden.current,'is-hidden');
      }else{
        cssClassManager.remove_class(menu_hidden.current,'is-hidden');
      }
      valueScroll = windowY;
      cssClassManager.add_class(menu_hidden.current,'bg-active');
    }else{
      cssClassManager.remove_class(menu_hidden.current,'bg-active');
    }
  }

  function get_item(_text, _to, _tag, _onClick){
    return (
      _onClick != undefined ?
        <div className={`header_menu--link ${_tag}`} onClick={_onClick}>
          <p>{_text}</p>
        </div>
      :
        <Link className={`header_menu--link ${_tag}`} to={_to}>
          <p>{_text}</p>
        </Link>
    )
  }


  return (
    <header className={`main_header ${_className}`} id='header__container' ref={menu_hidden}>
      <div className="header_top">
        <Link to='/' className='header-link'>
          <div className="header_logo_container">
            <CinsaLogoText />
          </div>
        </Link>
        <div className="header_menu">
          <div className="header_menu--top">
            {get_item('Nosotros','/nosotros', 'tag-header-menu-nosotros')}
            {get_item('Código de Ética','/codigo-de-etica', "tag-header-codigo-de-etica")}
            {get_item('Promociones','/promociones', 'tag-header-menu-promociones')}
            {get_item('Contáctanos','/contactanos', 'tag-header-menu-contactanos')}
            {get_item('Solicita Ayuda','/solicita-ayuda', 'tag-header-menu-solicita-ayuda')}
            {get_item('Compra en Línea','/compra-en-linea', 'tag-header-menu-compra-en-linea')}
            {get_item('Newsletter','/newsletter', 'tag-header-menu-newsletter')}
          </div>
          <div className="header_menu--bottom">
            {get_item('Modelos','', 'tag-header-menu-modelos', ()=>open_burger(true))}
            {get_item('Comparador','/comparador', 'tag-header-menu-comparador')}
            {get_item('Distribuidores','/distribuidores', 'tag-header-menu-distribuidores')}
            {get_item('Blog','/blog', 'tag-header-menu-blog' )}
            {get_item('Tu calentador ideal','/tu-calentador-ideal', 'tag-header-menu-tu-calentador-ideal')}
          </div>
        </div>
        <div className="header_menu__mobile">
          <div className="header_menu_products">
            <div className="header_menu_products-link" onClick={()=>open_burger(true)}>Modelos</div>
          </div>
          <div className="header_menu__burger" ref={open_burger_button} onClick={()=>open_burger(false)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="header_menu__close" onClick={()=>handleCloseMenu()}>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </header>
    )
}

export default Header
