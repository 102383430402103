import React from 'react'
import { Link } from 'gatsby'
import "../styles/_layout/footer/footer.scss"
import CinsaLogoText from '../components/svg/commons/cinsa_logo_text';
import FooterShape from '../components/svg/commons/footer_shape';
import Social from '../content/home/social_networks/social_networks'
import { useWindowSize } from '../hooks/use_window-size'


const Footer = () => {
    const windowSize = useWindowSize();
    return (
        <footer>
            <div className="footer__container">
                <div className="footer__logo--container">
                    <CinsaLogoText fill_text='#001f5b' />
                </div>
                { windowSize.width < 768 &&  <Social/>}
            </div>
            <div className="footer__content">
                <div className="legal__container">
                    <span>©2020 Cinsa, confiable como tú</span>
                    <span className='separator__space'>|</span>
                    <Link to="/aviso-de-privacidad"><span>Aviso de privacidad</span></Link>
                </div>
                { windowSize.width >= 768 &&  <Social/>}
            </div>
        </footer>
    )
}

export default Footer
