import React from "react";
import styles from '../../styles/_components/_chat/_chat.module.scss'

const Chat=()=>{    

    function open_chat()
    {
      if(navigator.userAgent.toLowerCase().indexOf('opera') !== -1 && window.event.preventDefault) window.event.preventDefault(); 
      let chat = window.open('https://savethechildren.s1gateway.com/pe/CFRHAhf7r4wh', 'S1Gateway', 'toolbar=0,scrollbars=0,location=0,status=0,menubar=0,width=402,height=590,resizable=0'); 
      
      chat.focus(); 
      var windowwidth = 402; 
      var windowheight = 590; 
      var screenwidth = window.screen.availWidth; 
      var screenheight = window.screen.availHeight; 
      chat.moveTo(screenwidth - windowwidth,screenheight - windowheight);
      chat.opener=window;
      return false;
    }
    return(
        <div className={`o-chat_module_container ${styles.oChat_container}`}>
            {/* <div className={`o-chat_button ${styles.oChat_button}`} onClick={()=>open_chat()}>
                <div className={`o-chat_points_container ${styles.oChat_points_container}`}>
                    <div className={styles.oChat_point}></div>
                    <div className={styles.oChat_point}></div>
                    <div className={styles.oChat_point}></div>
                </div>
                <p className={`o-chat_text ${styles.oChat_text}`}>¡Hola! ¿Tienes una duda?</p>
            </div> */}
        </div> 
    )
}
export default Chat