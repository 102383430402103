import { useStaticQuery, graphql } from "gatsby"
export const usePrismCalentadores = () => {
  const prismicCalentadores = useStaticQuery(
    graphql`
    {
      allPrismicModel{
        edges{
          node{
            uid
            data{
              order
              name{
                text
              }
              menu_image{
                url
                alt
                lg{
                  url
                }
                tb{
                  url
                }
                mb{
                  url
                }
              }
            }
          }
        }
      }
    }
    `
  )
  return prismicCalentadores.allPrismicModel

}