import React,{ useEffect, useState } from 'react'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import { Picture } from 'react-responsive-picture'
import { useCurrenDevice, DEVICES } from '../../hooks/useCurrentDevice'
import '../../styles/_components/_menu/_menu_models.scss'
import BlogImageSpot from '../../components/svg/blog/blog_image_spot'


const MenuModels = ({ modelsQuery }) => {

    const [items, setItems] = useState([])
    const [device] = useCurrenDevice()
    let arrayCalentador = []

    useEffect(() => {
        modelsQuery.edges.map(calentador => {
          arrayCalentador.push(calentador)
        })
        arrayCalentador.sort((a, b) => a.node.data.order - b.node.data.order);
        setItems(arrayCalentador)
    },[])

    const settings = {
        customPaging: function(i) {
            return (
              <span className='pagination__bullet'></span>
            );
        },
        dots: true,
        dotsClass: 'pagination__dots',
        lazyLoad: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    function getModel(model){
        return(
            <Link to={`/calentadores/${model.node.uid}`} className="menu_model_slide" key={ model.node.uid }>
                <div className="menu_model_image">
                    <BlogImageSpot />
                    <Picture
                        sources={[
                            {
                                srcSet: model.node.data.menu_image.url
                            }
                        ]}
                    />
                </div>
                <div className="menu_model_info">
                    <div className="menu_model_title">CINSA</div>
                    <div className="menu_model_name">{ model.node.data.name.text }</div>
                </div>
            </Link>
        )
    }

    return (
        <div className="menu_models">
            {
                device === DEVICES.mobile ?
                <Slider { ...settings } >
                {
                    items.map(model => {
                        return (
                            getModel( model )
                        )
                    })
                }
                </Slider>
                :
                <div className="menu_models_container">
                    {
                        items.map(model => {
                            return (
                                getModel( model )
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default MenuModels